import { axiosPrivate } from "../api/axiosCore";

export const serviceGetChatbots = async (token, page, depIDs) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const departmentIds = depIDs === "All" ? "" : `&departmentIds=${depIDs}`
    const response = await axiosPrivate.get("api/chat-rules/?ruleTemplateType=MASTER&take=10&page="+page + "" + departmentIds);
    // const response = await axiosPrivate.get("api/chat-rules/?take=15&page="+page);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetChatbotsByQuery = async (token, page,query) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const q = query ? `&q=${query}` : "";
    const response = await axiosPrivate.get(`api/chat-rules/?f=chatrule.ruleName&take=10&page=${page || 1}`+q+"&c=AND");
    // const response = await axiosPrivate.get("api/chat-rules/?take=15&page="+page);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const serviceGetChatbot = async (token,id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.get("api/chat-rules/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteChatbot = async (token,id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/chat-rules/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const serviceCreateChatbot = async (token, data) => {
  try {
    const myInterceptor = axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      // Change "Content-Type" to "application/json"
      config.headers["Content-Type"] = "application/json";
      return config;
    });
    const response = await axiosPrivate.post("api/chat-rules", data);
    axiosPrivate.interceptors.request.eject(myInterceptor);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceStoreMediaChatbot = async (token, data) => {
  try {
    const myInterceptor = axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await axiosPrivate.post("api/chat-rules/upload-media", data);
    axiosPrivate.interceptors.request.eject(myInterceptor);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};


export const servicePutChatbot = async (token, id, data) => {
    try {
      axiosPrivate.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  
      const response = await axiosPrivate.put("api/chat-rules/" + id, data);
  
      return response;
    } catch (error) {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  };