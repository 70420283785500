import { MessagesContext } from "contexts/messagesContext";
import { ReplyContext } from "contexts/replyContext";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { Input, Nav, NavItem, NavLink } from "reactstrap";
import { serviceGetChatById, serviceGetChats, serviceGetChatsByAdminStatus, servicePutChat } from "services/chats";
import { serviceGetContactbyIDS } from "services/contact";
import { getDepartment, getDepartmentByID } from "services/departments";
import { serviceGetFilterMessage } from "services/messages";
import { serviceGetTicketById } from "services/tickets";
import SimpleBar from "simplebar-react";
import { useDebounce } from "use-debounce";
import { Icon } from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import { ChatAsideBody } from "./ChatAsideBody";
import ChatBody from "./ChatBody";
import { ChatContext } from "./ChatContext";
import ChatModalFilter from "./ChatModalFilter";

import { socketIO } from "services/socket";
import { serviceGetTicketsById } from "services/tickets";
import lonceng4 from "../../../assets/sound/lonceng4.mp3";

const Chat = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [filterTab, setFilterTab] = useState("all");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [afterCursorSearchedMessage, setAfterCursorSearchedMessage] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterTag, setFilterTag] = useState([]);
  const [searchText] = useDebounce(filterText, 1000);
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [isModalTagAssignOpen, setIsModalTagAssignOpen] = useState(false);
  const [checkedTag, setCheckedTag] = useState([]);
  const [chatId, setChatId] = useState([]);
  const { chatState, chatIdState, isSavedArray } = useContext(ChatContext);
  const { messagesValue, statusValue, salesUpdate } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;
  const [cookies] = useCookies(["user"]);
  const [isModalTagFilterOpen, setIsModalTagFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingOnce, setLoadingOnce] = useState(false);

  const [replyMessage, setReplyMessage] = useState(null);
  const [contextMessage, setContextMessage] = useState(null);
  const [afterCursorChat, setAfterCursorChat] = useState(null);
  const [assignMessage, setAssignMessage] = useState();
  const [newSalesUpdate, setNewSalesUpdate] = useState([]);

  const [chat, setChat] = chatState;
  const [chatIdList, setChatIdList] = chatIdState;
  const [isSaved, setIsSaved] = isSavedArray;
  const { search } = useLocation();
  const chatID = new URLSearchParams(search).get("chatID");

  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["chats"].access || [];

  if (cookies.role.name !== "SALES" && cookies.role.name !== "CS") {
    roleFunctions = roleFunctions.filter((rf) => rf !== "chat");
  }
  const refreshChatList = async () => {
    const status = activeTab !== "all" ? activeTab.toUpperCase() : "";
    setIsLoading(true);
    if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
      const responseGetChats = await serviceGetChats(
        cookies.SIDikey,
        cookies.id,
        cookies.departmentIds,
        status,
        filterTag.toString(),
        roleFunctions
      );
      if (new RegExp(/20+/).test(responseGetChats.status)) {
        let data = responseGetChats.data.data;

        // save isSaved and contactID to context
        // let contactIds = data.map(item => item.contactId)
        // let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
        // contacts = contacts.filter((contact, index) => contact.data.id === contactIds[index])
        // let isSavedArray = contacts.map(contact => ({ id: contact.data.id, isSaved: contact.data.isSaved }));
        // setIsSaved(isSavedArray);

        const metaData = responseGetChats.data.meta;
        setAfterCursorChat(metaData);
        // sorting chat order by lastmessage timestamp
        try {
          if (data.length > 1) {
            //sorting
            data.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
            //filtering chatData
            // data = data.filter((item)=>{return item.status !== 'RESOLVED'});
          }
        } catch (error) {
          console.log("unsort", error);
        }
        setChat(data);
        setChatIdList(
          data.map((item) => {
            return item.id;
          })
        );
        // setFilteredChatList(data);
      } else {
        console.log("Error get Message from DB", responseGetChats);
      }
    } else {
      const responseGetChats = await serviceGetChatsByAdminStatus(
        cookies.SIDikey,
        cookies.id,
        cookies.departmentIds,
        status,
        filterTag.toString(),
        roleFunctions
      );
      if (new RegExp(/20+/).test(responseGetChats.status)) {
        const data = responseGetChats.data.data;

        // save isSaved and contactID to context
        // let contactIds = data.map(item => item.contactId)
        // let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
        // contacts = contacts.filter((contact, index) => contact.data.id === contactIds[index])
        // let isSavedArray = contacts.map(contact => ({ id: contact.data.id, isSaved: contact.data.isSaved }));
        // setIsSaved(isSavedArray);

        const metaData = responseGetChats.data.meta;
        setAfterCursorChat(metaData);
        // sorting chat order by lastmessage timestamp
        try {
          if (data.length > 1) {
            data.sort((a, b) => b.lastMessage?.timestamp || 0 - a.lastMessage?.timestamp || 0);
          }
        } catch (error) {
          console.log("unsort", error);
        }
        setChat(data);
        setChatIdList(
          data.map((item) => {
            return item.id;
          })
        );
        // setFilteredChatList(data);
      } else {
        console.log("Error get Message from DB", responseGetChats);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isModalTagAssignOpen) {
      refreshChatList();
    }
  }, [isModalTagAssignOpen, activeTab, filterTag]);

  // useEffect(() => {
  //   //Simpan data contact ke dalam context isSaved
  //   let contactIds = chat.map(item => item.contactId)

  //   if(contactIds.length > 0) {
  //     fetchContacts(contactIds);
  //   }
  // }, [chat]);

  useEffect(() => {
    //Simpan data contact ke dalam context isSaved
    let contactIds = chat.map((item) => item.contactId);

    if (contactIds.length > 0) {
      fetchContacts(contactIds);
    }
  }, []);

  const fetchContacts = async (contactIds) => {
    const contacts = await serviceGetContactbyIDS(cookies.SIDikey, contactIds);
    //let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
    let newIsSavedArray = contacts.data.data.map((contact) => ({ id: contact?.id, isSaved: contact.isSaved }));
    setIsSaved((oldArray) => {
      const newArray = [...oldArray];
      newIsSavedArray.forEach((newItem) => {
        if (!newArray.some((oldItem) => oldItem.id === newItem.id)) {
          newArray.push(newItem);
        }
      });
      return newArray;
    });
  };

  // if the opened chat have new message
  const playSound = () => {
    try {
      const sound = new Audio(lonceng4);
      sound.play();
    } catch (error) {
      console.log(error);
    }
  };

  const refreshChatDataOnNewMessage = (chatData, newMessage) => {
    let newChats = [];
    let found = false;
    let foundIndex = -1;
    chatData.forEach((element, i) => {
      let ch = element;
      if (newMessage.chat?.userId !== element.userId && roleFunctions.includes("chat")) {
        if (selectedId === newMessage.chat?.id) {
          setSelectedId(null);
        }
      } else if (newMessage.chatId === element.id) {
        found = true;
        foundIndex = i;
        if (!newMessage.fromMe) {
          try {
            playSound();
          } catch (error) {
            console.log(error);
          }
        }

        if (newMessage.chat?.user) {
          const newUser = newMessage.chat.user;
          ch.userId = newMessage.chat.userId;
          ch.user.firstName = newUser?.firstName;
          ch.user.lastName = newUser?.lastName;
        }

        //jika tiket berpindah
        if (newMessage?.tickets) {
          ch.tickets = newMessage.tickets;
        }

        //jika tag berubah
        if (newMessage?.tags) {
          ch.tags = newMessage.tags;
        }

        if (newMessage.type !== "tag-update") {
          if (newMessage.type !== "tag-update-resolve") {
            ch.lastMessage = newMessage;
          }
        }

        if (ch.status === "PENDING") {
          if (newMessage?.chat?.status === "RESOLVED") {
            ch.status = newMessage?.chat?.status || ch.status;
          }
        } else {
          ch.status = newMessage?.chat?.status || ch.status;
        }

        if (ch.adminStatus === "PENDING") {
          if (newMessage?.chat?.adminStatus === "RESOLVED") {
            ch.adminStatus = newMessage?.chat?.adminStatus || ch.adminStatus;
          }
        } else {
          ch.adminStatus = newMessage?.chat?.adminStatus || ch.adminStatus;
        }

        ch.unreadCount = newMessage?.chat?.unreadCount ? newMessage?.chat?.unreadCount : ch.unreadCount;
        ch.unreadCountAdmin = newMessage?.chat?.unreadCountAdmin
          ? newMessage?.chat?.unreadCountAdmin
          : ch.unreadCountAdmin;

        if (selectedId === newMessage.chatId) {
          if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
            if (newMessage?.chat?.status === "UNOPENED" && cookies.id === newMessage.chat.userId) {
              ch.status = ch.status === "PENDING" ? "PENDING" : "OPENED";
              ch.unreadCount = 0;
              ch.unreadCountAdmin = 0;

              const updateTicketStatus = {
                type: "status-update",
                id: ch.id,
                ticketId: newMessage.ticketId,
                chat: {
                  id: ch.id,
                  status: ch.status === "PENDING" ? "PENDING" : "OPENED",
                  user: ch.user,
                },
                chatId: ch.id,
                fromMe: true,
              };
              setTimeout(function () {
                socketIO.emit("admin", JSON.stringify(updateTicketStatus));
              }, 3000);
              putSelectedChat(cookies.SIDikey, selectedId, { status: "OPENED", unreadCount: 0 });
            } else {
              putSelectedChat(cookies.SIDikey, selectedId, { unreadCount: 0 });
            }
          } else {
            ch.unreadCount = 0;
            ch.unreadCountAdmin = 0;
            if (newMessage?.chat?.adminStatus === "UNOPENED") {
              ch.adminStatus = ch.adminStatus === "PENDING" ? "PENDING" : "OPENED";
              putSelectedChat(cookies.SIDikey, selectedId, { adminStatus: "OPENED", unreadCountAdmin: 0 });
            } else {
              putSelectedChat(cookies.SIDikey, selectedId, { unreadCountAdmin: 0 });
            }
          }
        }

        newChats = [ch, ...newChats];
      } else {
        newChats.push(ch);
      }
    });

    return { newChats: newChats, found: found, foundIndex: foundIndex };
  };

  useEffect(() => {
    if (!salesUpdate[0] || chat.length === 0) {
      return;
    }
    assignChat(salesUpdate)
  }, [newSales]);

  const assignChat = async (emitData) => {
    let newChatSales = []
    await Promise.all(emitData.map(async (salesArr) => {
      if (!salesArr) {
        return false;
      }
      await Promise.all(salesArr.map(async (sales) => {
        if (!sales) {
          return false;
        }
        const targetChatIndex = chat.findIndex((item) => item.id === sales.chatId);
        if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
          if (sales.userId !== cookies.id) {
            if (targetChatIndex >= 0) {
              chat.splice(targetChatIndex, 1);
              //setChat([...chat]);
            }
          } else {
            if (targetChatIndex < 0) {
              //addNewChatToChats(sales);
              if(sales.chat) {
                //addNewChatToChats(sales)
              } else {
                if (sales.userId === cookies.id) {
                  const resNewChat = await serviceGetChatById(cookies.SIDikey, sales.chatId);
                  if (resNewChat.status === 200) {
                    const resDeptName = await getDepartment(cookies.SIDikey, resNewChat.data.user.departmentId);
                    if (resDeptName.status === 200) {
                      const getCurrentDept = resDeptName.data.data.find((item) => item.id === resNewChat.data.user.departmentId);
                      const newChat = resNewChat.data;
                      const getTicketBychatId = await serviceGetTicketsById(cookies.SIDikey, sales.ticket[0].id);
                      if (getTicketBychatId.status === 200) {
                        newChat.tickets = getTicketBychatId.data.data;
                        newChat.user.department = getCurrentDept;
                        newChat.lastMessage.ticketId = sales.ticket[0].id;
                        newChat.channel.department = sales.department
                        newChatSales.push(newChat)
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (targetChatIndex >= 0) {
            if (sales.userId !== chat[targetChatIndex].userId) {
              sales.user.department = sales.department;
              chat[targetChatIndex].user = sales.user;
              chat[targetChatIndex].userId = sales.userId;
              //setChat([...chat]);
            }
          }
        }
      }));
    }));
    setChat([...newChatSales, ...chat])
  }

  useEffect(() => {
    //unread refresh algorithm
    let newMessage = newMessages.at(-1);
    if (newMessage === undefined || newMessage.type === "status-update") {
      return;
    }

    if (chat.length === 0) {
      addNewChatToChats(newMessage);
      return;
    }

    if (searchText) {
      const addNewMessage = refreshChatDataOnNewMessage(filteredChatList, newMessage);
      const newChats = addNewMessage.newChats;
      const found = addNewMessage.found;
      if (found) {
        setFilteredChatList(newChats);
      }
    }

    const addNewMessage = refreshChatDataOnNewMessage(chat, newMessage);
    const newChats = addNewMessage.newChats;
    const found = addNewMessage.found;

    if (found) {
      if (newMessage.ticketId && newChats[addNewMessage.foundIndex].tickets) {
        if (newChats[addNewMessage.foundIndex].tickets[0].id !== newMessage.ticketId) {
          addNewTicketToChat(newChats, newMessage.ticketId, addNewMessage.foundIndex);
        } else {
          setChat([...newChats]);
        }
      } else {
        addNewTicketToChat(newChats, newMessage.ticketId, addNewMessage.foundIndex);
      }
    } else {
      addNewChatToChats(newMessage);
    }
    newMessages.pop();
  }, [newMessages]);

  const addNewTicketToChat = async (newChats, newTicketId, msgIndex) => {
    if (!newTicketId) return;
    const resNetTicket = await serviceGetTicketsById(cookies.SIDikey, newTicketId);
    newChats[msgIndex].tickets = resNetTicket.data.data;
    newChats[msgIndex].ticketId = resNetTicket.data.data[0].id;
    setChat([...newChats]);
  };

  const putSelectedChat = async (token, id, data) => {
    await servicePutChat(token, id, data);
  };

  const addNewChatToChats = async (newMessage) => {
    if (newMessage.chat) {
      // emit coming from chat
      const isHaveTargetChat = chat.findIndex((ch) => ch.id === newMessage.chat.id);
      const newChat = newMessage.chat;
      newChat.lastMessage = newMessage;
      newChat.tags = !newChat.tags ? [] : newChat.tags;
      if (!newChat.channel && newChat.tickets) {
        const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
        if (resDeptName.status === 200) {
          newChat.channel = {
            department: {
                name:resDeptName.data.name,
                color:resDeptName.data.color
            }
          };
        }
      }

      if (newMessage.chat.user && newMessage.chat.user.id !== cookies.id) {
        // chat pindah sales lain
        if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
          if (isHaveTargetChat >= 0) {
            //const newChat = [...chat];
            chat.splice(isHaveTargetChat, 1);
            //setChat([...newChat]);
          }
        } else {
          if (isHaveTargetChat < 0) {
            const resNewChat = await getMissingChatData(newChat, newMessage);
            setChat([resNewChat, ...chat]);
          }
        }
      } else {
        if (isHaveTargetChat < 0) {
          const resNewChat = await getMissingChatData(newChat, newMessage);
          setChat([resNewChat, ...chat]);
        }
      }
    } else {
      // emit coming from rotator
      if (newMessage.userId === cookies.id) {
        const resNewChat = await serviceGetChatById(cookies.SIDikey, newMessage.chatId);
        if (resNewChat.status === 200) {
          const resDeptName = await getDepartment(cookies.SIDikey, resNewChat.data.user.departmentId);
          if (resDeptName.status === 200) {
            const getCurrentDept = resDeptName.data.data.find((item) => item.id === resNewChat.data.user.departmentId);
            const newChat = resNewChat.data;
            const getTicketBychatId = await serviceGetTicketsById(cookies.SIDikey, newMessage.ticket[0].id);
            if (getTicketBychatId.status === 200) {
              newChat.tickets = getTicketBychatId.data.data;
              newChat.user.department = getCurrentDept;
              newChat.lastMessage.ticketId = newMessage.ticket[0].id;
              newChat.channel.department = newMessage.department
              setChat([newChat, ...chat]);
            }
          }
        }
      }
    }
    playSound();
  };

  const getMissingChatData = async (newChat, newMessage) => { // multi department bug
    const newChatData = newChat
    if (!newChat.tickets) {
      const resGetTickets = await serviceGetTicketById(cookies.SIDikey, newMessage.ticketId);
      if (resGetTickets.status === 200) {
        newChatData.tickets = [resGetTickets.data];
      }
    }
    if (!newChat.contactId) {
      const resGetChat = await serviceGetChatById(cookies.SIDikey, newMessage.chat.id);
      if (resGetChat.status === 200) {
        newChatData.contactId = resGetChat.contactId;
      }
    }
    //if (!newChat.user.department) {
    //  const resDeptName = await getDepartment(cookies.SIDikey, newChat.user.departmentId);
    //  if (resDeptName.status === 200) {
    //    const getCurrentDept = resDeptName.data.data.find((item) => item.id === newChat.user.departmentId);
    //    newChat.user.department = getCurrentDept;
    //  }
    //}
    if (!newChat.channel && newChat.tickets) {
      const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
      if (resDeptName.status === 200) {
        newChatData.channel = {
          department: {
              name:resDeptName.data.name,
              color:resDeptName.data.color
          }
        };
      }
    }
    return newChat
    //setChat([newChat, ...chat]);
  };
  // Filtering users by search
  useEffect(() => {
    if (searchText !== "") {
      const filteredObject = chat.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
      filterMessage();
      setFilteredChatList([...filteredObject]);
    } else {
      setFilteredChatList([]);
      setAfterCursorSearchedMessage(null);
    }
  }, [searchText, setFilteredChatList, setFilteredMessageList]);

  useEffect(() => {
    let newChats = [];
    chat.forEach((ch) => {
      if (ch.id === selectedId) {
        if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
          if (ch?.status === "UNOPENED") {
            ch.status = "OPENED";
            ch.unreadCount = 0;
          }
        } else {
          if (ch?.adminStatus === "UNOPENED") {
            ch.adminStatus = "OPENED";
            ch.unreadCountAdmin = 0;
          }
        }
      }
      newChats.push(ch);
    });
    setChat([...newChats]);
  }, [selectedId]);

  const filterMessage = async (id) => {
    const responseGetMessages = await serviceGetFilterMessage(
      cookies.SIDikey,
      cookies.role.name,
      searchText,
      cookies.id
    );
    if (new RegExp(/20+/).test(responseGetMessages.status)) {
      const messageData = responseGetMessages.data.messages.data;
      const chatData = responseGetMessages.data.chats.data;
      chatData.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
      const filteredChatData = chatData.filter((item) => {
        return !chatIdList.includes(item.id);
      });
      setChat((chat) => [...chat, ...filteredChatData]);
      setChatIdList((chatIdList) => [
        ...chatIdList,
        filteredChatData.map((item) => {
          return item.id;
        }),
      ]);
      setFilteredChatList(chatData);
      setFilteredMessageList(messageData);
      setAfterCursorSearchedMessage(responseGetMessages.data.messages.meta);
    } else {
      console.log("Error get Messages from DB", responseGetMessages);
    }
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
  };

  const chatItemClick = (id) => {
    //let data = chat;
    //setChat([...data]);
    setSelectedId(id);
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  useEffect(() => {
    if (chatID && !loadingOnce && chat && !isLoading) {
      chatItemClick(chatID);
      setLoadingOnce(true);
    }
  }, [isLoading]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    onFilterClick(tab);
  };

  return (
    <React.Fragment>
      <Head title="Chat / Messenger"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <SimpleBar className="">
              <div className="d-flex justify-content-center" style={{ height: "40px", marginLeft: "110px" }}>
                <Nav tabs style={{ border: "none", flexWrap: "nowrap" }}>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "all" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("all");
                      }}
                    >
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "unopened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("unopened");
                      }}
                    >
                      Unopened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "opened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("opened");
                      }}
                    >
                      Opened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "pending" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("pending");
                      }}
                    >
                      Pending
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        marginRight: "10px",
                        height: "40px",
                        color: activeTab === "resolved" ? "#4461F2" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        toggleTab("resolved");
                      }}
                    >
                      Resolved
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </SimpleBar>
            <div className="nk-chat-aside-search p-2" style={{ backgroundColor: "#e5e9f2", marginBottom: "15px" }}>
              <div className="form-group">
                <div className="form-control-wrap d-flex">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <Input
                    type="text"
                    className="form-round"
                    id="default-03"
                    placeholder="Search by name"
                    onChange={(e) => onInputChange(e)}
                    style={{ backgroundColor: "white" }}
                  />
                  <div
                    className="btn btn-round btn-icon btn-light dropdown-toggle ml-2"
                    onClick={() => setIsModalTagFilterOpen(true)}
                  >
                    <ChatModalFilter
                      cookies={cookies}
                      isModalTagFilterOpen={isModalTagFilterOpen}
                      setIsModalTagFilterOpen={setIsModalTagFilterOpen}
                      setFilterTag={setFilterTag}
                      filterTag={filterTag}
                    />
                    <Icon name="filter-alt"></Icon>
                    {filterTag.length > 0 ? <span className="status dot dot-lg dot-success"></span> : <></>}
                  </div>
                </div>
              </div>
            </div>
            {filteredChatList ? (
              <ChatAsideBody
                cookies={cookies}
                onInputChange={onInputChange}
                chat={chat}
                setChat={setChat}
                chatId={chatId}
                setChatId={setChatId}
                setCheckedTag={setCheckedTag}
                isModalTagAssignOpen={isModalTagAssignOpen}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                filteredChatList={filteredChatList}
                setFilteredChatList={setFilteredChatList}
                afterCursorChat={afterCursorChat}
                setAfterCursorChat={setAfterCursorChat}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                isSearch={searchText !== "" ? true : false}
                filteredMessageList={filteredMessageList}
                setFilteredMessageList={setFilteredMessageList}
                afterCursorSearchedMessage={afterCursorSearchedMessage}
                setAfterCursorSearchedMessage={setAfterCursorSearchedMessage}
                searchText={searchText}
                isLoading={isLoading}
                status={activeTab !== "all" ? activeTab.toUpperCase() : ""}
                filterTag={filterTag}
                setContextMessage={setContextMessage}
                roleFunctions={roleFunctions}
              />
            ) : (
              <></>
            )}
          </div>
          {selectedId !== null ? (
            <ReplyContext.Provider
              value={{ value: [replyMessage, setReplyMessage], value2: [contextMessage, setContextMessage] }}
            >
              <ChatBody
                AfterAssign={() => refreshChatList()}
                cookies={cookies}
                id={selectedId}
                mobileView={mobileView}
                setMobileView={setMobileView}
                setSelectedId={setSelectedId}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                isModalTagAssignOpen={isModalTagAssignOpen}
                chatId={chatId}
                setChatId={setChatId}
                checkedTag={checkedTag}
                setCheckedTag={setCheckedTag}
                assignMessage={assignMessage}
              />
            </ReplyContext.Provider>
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon name="chat" className="icon-circle icon-circle-xxl bg-white"></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <h6>Please select a chat to start messaging</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>
    </React.Fragment>
  );
};

export default Chat;
