import { axiosPrivate } from "../api/axiosCore";

const limit = 15;
const ALL_DEPT = 'Access_All_Dept'
const OWN_DEPT = 'Access_Own_Dept'
const OWN = 'Access_Own'

export const serviceGetUsers = async (token, roleFunctions, departmentId, page, userPerPage, query, userId = '') => {
  roleFunctions = roleFunctions || [];
  departmentId = departmentId || '';

  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let queryData = "";
    let c="";    
    if(query) {
      if (roleFunctions.includes(OWN)) {
        queryData = query
      } else {
        // departmentId = ''
        if (query.includes(" ")) {
          let splittedInput = query.split(" ").filter(Boolean);
          let firstName = splittedInput[0];
          let lastName = splittedInput[1];
          c = `&c=AND`
          queryData = query ? `&q=${firstName},${lastName}` : "";
        } else {
          c = `&c=OR`
          queryData = query ? `&q=${query},${query}` : "";
        }
      }
    }
    

    
    const basicUrl = `api/users?take=${page? userPerPage : 50}&page=${page || 1}&order=DESC`
    var response = axiosPrivate.get(basicUrl);
    if (roleFunctions.includes(ALL_DEPT)){
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.lastName`+ queryData + c);
    }else if(roleFunctions.includes(OWN_DEPT)){
      // const deptIdQuery = departmentId !== '' ? `&departmentId=${departmentId}` : '';
      const deptIdQuery = departmentId !== '' ? `&departmentIds=${departmentId}` : '';
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.lastName` + deptIdQuery + queryData + c); //deptId
    }else if(roleFunctions.includes(OWN)){
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.createdBy.id`+queryData`%2C${userId}`+ c); //userId
    }else{
      response = await axiosPrivate.get(basicUrl+queryData);
    }

    // console.log(roleFunctions, departmentId,response);

    // const response = await axiosPrivate.get("api/users?take=50");

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersAndOrder = async (token, roleFunctions, departmentId, page, userPerPage, query, userId = '', field='', order="DESC") => {
  roleFunctions = roleFunctions || [];
  departmentId = departmentId || '';
  const fieldData = field ? "&fo="+field : "";
  const orderData = order ? "&order="+order : "";
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let queryData = "";
    let c="";    
    if(query) {
      if (roleFunctions.includes(OWN)) {
        queryData = query
      } else {
        departmentId = ''
        if (query.includes(" ")) {
          let splittedInput = query.split(" ").filter(Boolean);
          let firstName = splittedInput[0];
          let lastName = splittedInput[1];
          c = `&c=AND`
          queryData = query ? `&q=${firstName},${lastName}` : "";
        } else {
          c = `&c=OR`
          queryData = query ? `&q=${query},${query}` : "";
        }
      }
    }
    

    
    const basicUrl = `api/users?take=${page? userPerPage : 15}&page=${page || 1}${fieldData}${orderData}`
    var response = axiosPrivate.get(basicUrl);
    if (roleFunctions.includes(ALL_DEPT)){
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.lastName`+queryData+ c);
    }else if(roleFunctions.includes(OWN_DEPT)){
      const deptIdQuery = departmentId !== '' ? `&departmentIds=${departmentId}` : '';
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.lastName` + deptIdQuery +queryData + c); //deptId
    }else if(roleFunctions.includes(OWN)){
      response = await axiosPrivate.get(basicUrl+`&f=user.firstName%2Cuser.createdBy.id`+queryData`%2C${userId}`+ c); //userId
    }else{
      response = await axiosPrivate.get(basicUrl+queryData);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersByField = async (token, roleFunctions, departmentId, page, userPerPage,field, order="DESC") => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const fieldData = field ? "&f="+field : "";
    roleFunctions = roleFunctions || [];
    departmentId = departmentId || '';
    const basicUrl = `api/users?take=${page? userPerPage : 50}&page=${page || 1}&order=${order}`+fieldData
    var response = null;
    if (roleFunctions.includes(ALL_DEPT)){
      response = await axiosPrivate.get(basicUrl);
    }else if(roleFunctions.includes(OWN_DEPT)){
      response = await axiosPrivate.get(basicUrl+`&departmentIds=${departmentId}`); //deptId
    }else if(roleFunctions.includes(OWN)){
      response = null;
    }else{
      response = await axiosPrivate.get(basicUrl);
    }

    // console.log(roleFunctions, departmentId,response);

    // const response = await axiosPrivate.get("api/users?take=50");

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersByUserId = async (token, id) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/users?page=1&take=1&q=${id}=user.id`
    var response = await axiosPrivate.get(basicUrl);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersByRoleId = async (token, departmentIds, roleID) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const departmentIDs = departmentIds ? `&departmentIds=${departmentIds}` : ""
    const basicUrl = `api/users?page=1&take=50${departmentIDs}&roleId=${roleID}`
    var response = await axiosPrivate.get(basicUrl);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersByDeparmentId = async (token, departmentId) => { 
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let allUser = []
    let page = 1
    let hasNextPage = true
    while(hasNextPage) {
      const basicUrl = `api/users?take=${50}&page=${page}&order=DESC&departmentIds=${departmentId}`
      const response = await axiosPrivate.get(basicUrl); 
      response.data.data.map((data) => {
        allUser.push(data)
      })
      if (!response.data.meta.hasNextPage) {
        hasNextPage = false;
      } else {
        page += 1;
      }
    }

    const sales = allUser.filter((sal) => sal.role.name === "SALES")
    return sales;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersBySales = async (token, departmentId, role) => {
  const basicUrl = `api/users/sales-list`

  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    let response = null;
    const isAllDepartment = departmentId.split(',').length > 1
    
    if (role.includes("Access_All_Dept") && isAllDepartment) {
      response = await axiosPrivate.get(basicUrl);
    } else if (role.includes("Access_All_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own_Dept")) {
      response = await axiosPrivate.get(`${basicUrl}?departmentIds=${departmentId}`);
    } else if (role.includes("Access_Own")) {
      response = await axiosPrivate.get(`${basicUrl}?departmentIds=${departmentId}`);
    } else {
      response = await axiosPrivate.get(basicUrl);
    }

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetUsersWeightByDeptId = async (token, departmentId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    const basicUrl = `api/users/sales-list-weight?departmentId=${departmentId}`
    const response = await axiosPrivate.get(basicUrl);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePostUsers = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/users", data);

    return response;
  } catch (error) {
    if (error.response.status === 422) {
      return 'Invalid Phone Number'
    } else if (error.response.status === 409) {
      return 'email already exists'
    } else {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  }
};

export const servicePutUsers = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/users/" + id, data);
    return response;
  } catch (error) {
    if (error.response.status === 422) {
      return 'Invalid Phone Number'
    } else if (error.response.status === 409) {
      return 'email already exists'
    } else {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  }
};

export const servicePutAuthProfile = async (id, token, data) => {
  try {
    const myInterceptor = axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await axiosPrivate.put("api/auth/profile", data);
    axiosPrivate.interceptors.request.eject(myInterceptor);
    return response;
  } catch (error) {
    if (error.response.status === 422) {
      return 'Invalid Phone Number'
    } else if (error.response.status === 409) {
      return 'email already exists'
    } else {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  }
};

export const servicePutUsersOnline = async (token, userId, socketId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("api/users/status?userId="+userId+"&socketId="+socketId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutUsersWeigts = async (token,datas) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    datas.forEach(async (data) => {
      const fd = {weight:data.weight}
      const response = await axiosPrivate.put("api/users/"+data.id,fd);
      if( response.status >= 400){
        return 402;
      }
    });
    return 200;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceUpdateUserRotatorWeight = async (token,datas) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put("/api/users/sales-list-weight",datas);
    return 200;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutUsersOffline = async (token,userId) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post("api/auth/logout?userId="+userId);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
export const serviceDeleteUsers = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete("api/users/" + id);

    return response;
  } catch (error) {
    if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
  }
};

export const serviceGetUsersCount = async (token, roleFunctions, departmentId, date) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    roleFunctions = roleFunctions || [];
    departmentId = departmentId || '';
    const basicUrl = `api/users/rotator-summary?take=${limit}`
    // const response = await axiosPrivate.get(basicUrl+`&departmentId=${departmentId}&date=${date}`); //deptId
    const response = await axiosPrivate.get(basicUrl+`&departmentIds=${departmentId}&currentDate=${date.replaceAll("/","-")}`); //deptId
    
    // console.log(roleFunctions, departmentId,response);

    // const response = await axiosPrivate.get("api/users?take=50");

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
