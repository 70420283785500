/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { ChatDetailContext } from "contexts/chatDetailContext";
import { MessagesContext } from "contexts/messagesContext";
import { ReplyContext } from "contexts/replyContext";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import ChatModalAddContact from "pages/app/chat/ChatModalAddContact";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { servicePutChat, updateChatAdminReadCount, updateChatReadCount } from "services/chats";
import {
  serviceGetMessages,
  serviceGetMessagesAfterCursor,
  serviceSendMessage,
  serviceSendMessageDocument,
  serviceSendMessageImage,
  serviceSendMessageVideo,
} from "services/messages";
import { serviceGetQuickReply, serviceGetQuickReplyMedia } from "services/quickReply";
import { socketIO } from "services/socket";
import SimpleBar from "simplebar-react";
import { useDebounce } from "use-debounce";
import { useKeyPress } from "utils/Keypress";
import { Button, Icon, UserAvatar } from "../../../components/Component";
import getTextColor from "../../../components/custom/getTextColor";
import { delay } from "../../../utils/Utils";
import { ChatContext } from "./ChatContext";
import ChatModalAssign from "./ChatModalAssign";
import ChatModalResolve from "./ChatModalResolve";
import ChatModalTagAssign from "./ChatModalTagAssign";
import { MeChat, QuickReplyItem, SessionResolved, YouChat } from "./ChatPartials";
import ChatSearchSidebar from "./ChatSearchSidebar";
import ChatSideBar from "./ChatSideBar";
import { utilNumberPhoneConvention } from "./utils/ChatUtils";

import Swal from "sweetalert2";

const ChatBody = ({
  cookies,
  id,
  mobileView,
  setMobileView,
  setSelectedId,
  setIsModalTagAssignOpen,
  isModalTagAssignOpen,
  chatId,
  setChatId,
  checkedTag,
  setCheckedTag,
  AfterAssign,
  assignMessage,
}) => {
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const tabPress = useKeyPress("Tab");
  const backspacePress = useKeyPress("Backspace");
  const [selected, setSelected] = useState(undefined);
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const { deleteConvo, propAction, chatState } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const { messagesValue, statusValue } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newStatusMessages, setNewStatusMessages] = statusValue;
  const [Uchat, setUchat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [modalImage, setModalImage] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [sidebar, setsidebar] = useState(false);
  const [searchSidebar, setSearchSidebar] = useState(false);
  const [inputText, setInputText] = useState("");
  const [afterCursor, setAfterCursor] = useState("");
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [chatOptions, setChatOptions] = useState(false);
  const [isModalAssignOpen, setIsModalAssignOpen] = useState(false);
  const [isModalAddContactOpen, setIsModalAddContactOpen] = useState(false);
  const [isModalResolveOpen, setIsModalResolveOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [showQuickReply, setShowQuickReply] = useState(false);
  const [searchQuickReply, setSearchQuickReply] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [quickReplyData, setQuickReplyData] = useState([]);
  const [quickReplyState, setQuickReplyState] = useState(false);
  const [quickReplyInput, setQuickReplyInput] = useState("");
  const [quickReplyQuery] = useDebounce(quickReplyInput, 1000);
  const emojiButonRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const bodyChatRef = useRef(null);
  const inputChat = useRef(null);
  const inputChatMedia = useRef(null);
  const [changeChat, setChangeChat] = useState(false);
  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["chats"].access || [];
  // console.log("roleFunctions", roleFunctions)
  if (cookies.role.name !== "SALES" && cookies.role.name !== "CS") {
    roleFunctions = roleFunctions.filter((rf) => rf !== "chat");
  }

  // const [replyMessage, setReplyMessage] = useState(null);
  // const [contextMessage, setContextMessage] = useState(null);
  const { value, value2 } = useContext(ReplyContext);
  const [replyMessage, setReplyMessage] = value;
  const [contextMessage, setContextMessage] = value2;
  const [isLoading, setIsLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);

  // jika memilih suatu chat
  useEffect(() => {
    const dataChat = chat.find((item) => item.id === id);

    if (!dataChat) {
      return;
    }

    setMessages([]);

    if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
      if (dataChat?.unreadCount) {
        dataChat.unreadCount = 0;
        updateChatReadCount(cookies.SIDikey, id, 0);
      }
    } else {
      if (dataChat?.unreadCountAdmin) {
        dataChat.unreadCountAdmin = 0;
        updateChatAdminReadCount(cookies.SIDikey, id, 0);
      }
    }

    // if (cookies.role.name === "SALES" || cookies.role.name === "CS") {
    //   if (dataChat.status === "UNOPENED") {
    //     dataChat.status = "OPENED";
    //     handleStatusChange(dataChat.updatedAt);
    //   }
    // } else {
    //   if (dataChat.adminStatus === "UNOPENED") {
    //     dataChat.adminStatus = "OPENED";
    //     handleAdminStatusChange(dataChat.updatedAt);
    //   }
    // }

    setUchat((Uchat) => dataChat);
    setChangeChat(true);
    refreshMessages();
    setModalImage(false);
    setModalVideo(false);
    setImageFile(null);
    setVideoFile(null);
    setDocumentFile(null);
    setReplyMessage(null);
    setSearchSidebar(false);
    // setContextMessage(null);
    setShowQuickReply(false);
    setsidebar(false);
    refreshQuickReply();
    scrollToBottom();
  }, [id]);

  //NONEED Probably
  // useEffect(() => {
  //   const chatFind = chat.find((ch) => ch.id === id);
  //   if (!!chatFind) {
  //     setUchat(chatFind);
  //   }
  // }, [chat]);

  useEffect(() => {
    if (assignMessage) {
      setMessages((messages) => [...messages, assignMessage]);
    }
  }, [assignMessage]);

  const handleStatusChange = async (updatedAt) => {
    const resp = await servicePutChat(cookies.SIDikey, id, { status: "OPENED", updatedAt: updatedAt });
  };

  const handleAdminStatusChange = async (updatedAt) => {
    const resp = await servicePutChat(cookies.SIDikey, id, { adminStatus: "OPENED", updatedAt: updatedAt });
  };

  useEffect(() => {
    if (replyMessage !== null) {
      document.getElementById("real-default-textarea").focus();
    }
  }, [replyMessage]);

  const refreshMessages = async () => {
    setIsLoading(true);
    const responseGetMessages = await serviceGetMessages(cookies.SIDikey, id);
    setIsLoading(false);
    if (new RegExp(/20+/).test(responseGetMessages.status)) {
      const data = responseGetMessages.data;
      const messagesData = data.data.reverse();
      const metaData = data.meta;
      if (metaData.hasNextPage) {
        setAfterCursor(metaData.afterCursor);
      }
      setMessages(messagesData);
      await delay(200);
      scrollToBottom();
    } else {
      console.log("error Get Messagefrom DB, chatBody", responseGetMessages);
    }
  };
  const refreshQuickReply = async () => {
    if (!isSearching) {
      return;
    }

    let query = searchQuickReply.split("/").filter(Boolean).pop();
    let getAllDepartmentIds = []
    getAllDepartmentIds.push(Uchat.channel.departmentId)
    Uchat.tags.map(item => {
      if(item.departmentId){
        getAllDepartmentIds.push(item.departmentId)
      }
    })

    const responseGetQuickReply = await serviceGetQuickReply(
      cookies.SIDikey,
      query,
      // DefaultRoleFunctions.quickReply[cookies.role.name],
      ["Access_Own_Dept"],
      // cookies.departmentId,
      getAllDepartmentIds,
      cookies.id,
      1
    );
    // console.log(searchQuickReply,responseGetQuickReply);
    if (new RegExp(/20+/).test(responseGetQuickReply.status)) {
      const data = responseGetQuickReply.data.data;
      const finalData = [];
      data.forEach((reply) => {
        const tempData = {
          name: reply.name,
          content: reply.response,
          command: "/" + reply.command,
          media: reply.media,
        };
        finalData.push(tempData);
      });

      // setQuickReplyData(finalData.filter((item) => item.command.toLowerCase().includes(searchQuickReply)));
      setQuickReplyData(query ? finalData.filter((item) => item.command.includes(query)) : finalData);
    } else {
      console.log("responseGetQuickReply", responseGetQuickReply);
    }
  };

  useEffect(() => {
    refreshQuickReply();
  }, [searchQuickReply]);
  // }, [quickReplyQuery, searchQuickReply]);

  const addMessages = async (cursorSpec) => {
    let includeCursor = "true";
    const response = await serviceGetMessagesAfterCursor(cookies.SIDikey, id, cursorSpec, includeCursor);

    if (response.status === 200) {
      const data = response.data;
      const messagesData = data.data.reverse();
      const metaData = data.meta;

      messagesData.pop(); // delete last message

      setMessages((messages) => [...messagesData, ...messages]);
      //add messasges by click

      if (metaData.hasNextPage) {
        setAfterCursor(metaData.afterCursor);
      } else {
        setAfterCursor("");
      }

      setIsScrollTop(true);
      scrollToStill(messages.at(0)?.id);
    }
    if (afterCursor !== cursorSpec) {
      await new Promise((res) => setTimeout(res, 100));
      includeCursor = "true";
    }
    return response;
  };

  const scrollToStill = (id) => {
    if (id) {
      const element = document.querySelector(`[id='${id}']`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.classList.add("highlight__animate");
        setTimeout(() => {
          element.classList.remove("highlight__animate");
        }, 2000);
      }
    }
    // if (messagesEndRef.current) {
    //   const scrollHeight = messagesEndRef.current.scrollHeight;
    //   const height = messagesEndRef.current.clientHeight;
    //   const maxScrollTop = scrollHeight - height;
    //   // messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    //   messagesEndRef.current.scrollTop = height;
    // }
    // }
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      // messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      messagesEndRef.current.scrollTop = scrollHeight + 50;
    }
    // }
  };

  const onScrolling = async () => {
    if (messagesEndRef.current) {
      if ((messagesEndRef.current.scrollTop === 0) & (afterCursor !== "")) {
        // DO SOMETHING HERE if top is reached
      }
    }
  };

  const loadMoreMessages = async (e) => {
    e.preventDefault();
    if (messagesEndRef.current) {
      if (afterCursor !== "") {
        // TO SOMETHING HERE
        setIsScrollTop(true);
        const response = await addMessages(afterCursor);
        if (response.status !== 200) {
          console.log("error add messages", response);
        }
      }
    }
  };

  // jika ada perubahan di messages
  // update chats
  useEffect(() => {
    if (changeChat) {
      // setIsScrollTop(false);
      setInputText("");
      setQuickReplyState(false);
      setQuickReplyInput("");
      setChangeChat(false);
      return;
    }

    scrollToBottom();

    const message = messages.at(-1);
    if (message === undefined) {
      return;
    }

    let newChats = [];
    chat.forEach((element) => {
      let ch = element;
      if (message.chatId === element.id) {
        if (ch?.lastMessage?.id === message?.id) {
          return;
        }
        ch.lastMessage = message;
        newChats = chat.filter((item) => {
          return item.id !== message.chatId;
        });
        // newChats = [ch, ...newChats];
        setChat([ch, ...newChats]);
      } else {
        // newChats.push(ch);
      }
    });
  }, [messages]);

  const resizeFunc = () => {
    if (window.innerWidth > 1550) {
      setsidebar(true);
    } else {
      setsidebar(false);
    }
  };

  // contextMessage jump to context message
  useEffect(() => {
    if (contextMessage) {
      if (contextMessage.id.includes("fromSearchedMessage_")) {
        contextMessage.id.replace("fromSearchedMessage_", "");
        setTimeout(() => {}, 4000);
      }
      const element = document.querySelector(`[id='${contextMessage.id}']`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
        element.classList.add("highlight__animate");
        setContextMessage(null);
        setTimeout(() => {
          element.classList.remove("highlight__animate");
        }, 4000);
      } else {
        addMessages(contextMessage.cursor);
        setTimeout(() => {}, 2000);
        // const element = document.querySelector(`[id='${contextMessage.id}']`);
        setContextMessage(null);
      }
    }
  }, [contextMessage]);

  // jika ada perubahan di newMessages
  // if ada new message
  useEffect(() => {
    if (
      newMessages.length === 0 ||
      (newMessages.length > 0 && newMessages.at(-1).type === "status-update") ||
      (newMessages.length > 0 && newMessages.at(-1).type === "tag-update") ||
      (newMessages.length > 0 && newMessages.at(-1).type === "tag-update-resolve")
    ) {
      return;
    }

    const newPersonalMessages = newMessages.filter(function (msg) {
      try {
        let chatIDonMessage = null;
        if (
          msg.text &&
          msg.text.includes("Terima kasih telah memberikan penilaian Anda! Kami sangat menghargai masukan")
        ) {
          chatIDonMessage = msg?.chatId;
        } else {
          chatIDonMessage = msg?.chat?.id;
        }

        return (
          (msg?.chat?.customerPhone === Uchat?.customerPhone || chatIDonMessage === Uchat.id) &&
          msg?.type !== "status-update"
        );
      } catch (error) {
        console.log("error", error);
        return false;
      }
    });

    if (!!newPersonalMessages) {
      const msg_raw = newPersonalMessages.at(-1);
      if (msg_raw !== undefined) {
        setChangeChat(false);
        handleNewMessage(msg_raw);
      }
    } //jika message bukan chat ini
    // newMessages.pop()
  }, [newMessages]);

  const handleNewMessage = async (msg_raw) => {
    //fungsi agar scroll to bottom jika ada new message
    const news = messages
      .filter((msg) => msg.id !== msg_raw.id)
      .sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));
    if (news) {
      const tickets = Uchat.tickets;
      if (msg_raw.ticketId) {
        setUchat({ ...msg_raw.chat, tickets: [{ ...tickets[0], id: msg_raw.ticketId }] });
      } else {
        setUchat({ ...msg_raw.chat, tickets: [{ ...tickets[0] }] });
      }
      setSelectedId(msg_raw.chatId);
    }

    setMessages((messages) => [...news, msg_raw]);

    await delay(200);
    scrollToBottom();
  };

  // update status message
  useEffect(() => {
    const statusMessage = newStatusMessages.at(-1);
    const replaceMessages = messages;
    for (let index = replaceMessages.length - 1; index >= 0; index--) {
      if (replaceMessages[index]?.id === statusMessage?.id) {
        // setMessages(replaceMessages);
        replaceMessages[index] = statusMessage;
        setMessages([...replaceMessages]);

        let newChats = chat;
        for (let index = 0; index < newChats.length; index++) {
          const element = newChats[index];
          if (element.lastMessage.id === statusMessage.id) {
            newChats[index].lastMessage = statusMessage;
            setChat([...newChats]);
            break;
          }
        }
        return;
      }
    }
  }, [newStatusMessages]);

  const onChatOptions = () => {
    setChatOptions(!chatOptions);
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
    if (!e.target.value.includes("/")) {
      setQuickReplyState(false);
      setQuickReplyInput("");
    } else {
      setIsSearching(true);
      setSearchQuickReply(searchQuickReply.concat(e.nativeEvent.data));
      setQuickReplyInput(e.target.value);
    }
    if (isSearching && backspacePress) {
      setSearchQuickReply(searchQuickReply.slice(0, -1));
    }
  };

  const closeSearchBar = () => {
    setSearchSidebar(false);
  };

  const toggleMenu = () => {
    setsidebar(!sidebar);
    if (searchSidebar) {
      setSearchSidebar(false);
    }
  };

  const toggleSearch = () => {
    setSearchSidebar(!searchSidebar);
    if (sidebar) {
      setsidebar(false);
    }
  };

  const jumpReply = async (e) => {
    e.preventDefault();
  };

  const quickReplySelect = async (quickReply) => {
    let Input = inputText;
    const newInput = Input.replace(searchQuickReply, quickReply.content);
    setInputText(newInput);
    setQuickReplyState(false);
    setSearchQuickReply("");
    setQuickReplyInput("");
    document.getElementById("real-default-textarea").focus();

    if (quickReply.media) {
      const mediaFile = await serviceGetQuickReplyMedia(quickReply.media, quickReply.name);
      if (!mediaFile) return;

      setImageInput(mediaFile);
    }
  };

  const onTextSubmit = async (e) => {
    e.preventDefault();
    // let text = truncate(inputText, 74);
    let text = inputText.replace("%0A", "\r\n");
    let msg = isSearching
      ? {
          // text: text.replace(/(\r\n|\n|\r)/gm, ""),
          text: text,
          type: "TEXT",
          chatId: id,
        }
      : {
          text: text,
          type: "TEXT",
          chatId: id,
        };
    msg["wa_id"] = Uchat.customerPhone;
    if (replyMessage) {
      msg["contextId"] = replyMessage?.id;
    }

    if (imageFile) {
      if (imageFile.size > 64 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Picture too large",
          text: "Ukuran Gambar tidak boleh melebihi 64 MB",
          focusConfirm: false,
        });
        return;
      }
      if (imageFile.type.includes("image")) {
        msg.type = "IMAGE";
        const response = await serviceSendMessageImage(cookies.SIDikey, msg, imageFile);
        msg = response.data;
        setImageFile(null);
      } else {
        Swal.fire({
          icon: "error",
          title: "File unsupported",
          text: "Mohon gunakan gambar dengan format png/jpeg/jpg ",
          focusConfirm: false,
        });
        return;
      }
    } else if (documentFile) {
      if (documentFile.size > 64 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Ukuran file tidak boleh melebihi 64 MB",
          focusConfirm: false,
        });
        return;
      }
      msg.type = "DOCUMENT";
      msg.text = "";

      const response = await serviceSendMessageDocument(cookies.SIDikey, msg, documentFile);
      msg = response.data;
      setDocumentFile(null);
    } else if (videoFile) {
      if (videoFile.size > 64 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Video too large",
          text: "Ukuran Video tidak boleh melebihi 64 MB",
          focusConfirm: false,
        });
        return;
      }
      if (videoFile.type.includes("video")) {
        msg.type = "VIDEO";
        const response = await serviceSendMessageVideo(cookies.SIDikey, msg, videoFile);
        msg = response.data;
        setVideoFile(null);
      } else {
        Swal.fire({
          icon: "error",
          title: "File unsupported",
          text: "Mohon gunakan video dengan format mp4, 3gpp ",
          focusConfirm: false,
        });
        return;
      }
    } else {
      if (inputText === "") {
        return;
      }
      msg.type = "TEXT";
      const response = await serviceSendMessage(cookies.SIDikey, msg);
      msg = response.data;
      msg["fromMe"] = true;
    }
    if (replyMessage) {
      msg["context"] = replyMessage;
    }

    setMessages((messages) => [...messages, msg]);
    msg.status = "SENT";
    socketIO.emit("admin", JSON.stringify(msg));

    setInputText("");
    setReplyMessage(null);
    setModalImage(false);
    setModalDocument(false);
    setModalVideo(false);
    setIsScrollTop(false);
    setIsSearching(false);
  };

  const chatBodyClass = classNames({
    "nk-chat-body position-relative": true,
    "show-chat": mobileView,
    "profile-shown": (sidebar && window.innerWidth > 1550) || (searchSidebar && window.innerWidth > 1550),
    // "chat-body-shrink": searchSidebar,
  });

  const setImageInput = (file) => {
    setImageFile(file);
    setChatOptions(!chatOptions);
    setModalImage(true);
    setModalDocument(false);
    setModalVideo(false);
  };

  const setVideoInput = (file) => {
    setVideoFile(file);
    setChatOptions(!chatOptions);
    setModalVideo(true);
    setModalDocument(false);
    setModalImage(false);
  };

  const handleImageFileChange = (e) => {
    if (e.target.files) {
      setImageInput(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleVideoFileChange = (e) => {
    if (e.target.files) {
      setVideoInput(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleDocumentFileChange = (e) => {
    if (e.target.files) {
      setDocumentFile(e.target.files[0]);
      // setInputText(e.target.files[0].name);
      e.target.value = null;
      setChatOptions(!chatOptions);
      setModalDocument(true);
      setModalImage(false);
      setModalVideo(false);
    }
  };

  const convertFileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const k = 1000;
    const decimal = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const index = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, index)).toFixed(decimal)) + " " + sizes[index];
  };

  const handleUploadDocumentClick = () => {
    document.getElementById("selectDocument").click();
    if (documentFile) {
      setDocumentFile(null);
    }
  };

  const handleUploadImageClick = () => {
    document.getElementById("selectImage").click();
    if (imageFile) {
      setImageFile(null);
    }
  };

  const handleUploadVideoClick = () => {
    document.getElementById("selectVideo").click();
    if (videoFile) {
      setVideoFile(null);
    }
  };

  const onKeyDownHandler = (e) => {
    if (!quickReplyState) {
      if ((e.code === "Enter" && !e.shiftKey) || e.code === "NumpadEnter") {
        onTextSubmit(e);
      }
      if (quickReplyState) {
        if (e.code === "Slash" || e.code === "Space") {
          setQuickReplyState(false);
          setQuickReplyInput("");
        } else {
          if (e.keyCode >= 65 && e.keyCode <= 90) {
            const query = quickReplyInput + e.key;
            setQuickReplyInput(query);
          }
          if (e.code === "Backspace") {
            const query = quickReplyInput.slice(0, -1);
            setQuickReplyInput(query);
          }
        }
      } else {
        if (e.code === "Slash") {
          setQuickReplyState(true);
        }
      }
    }
  };
  const dateCheck = (input) => {
    return new Date(Number(input)).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
    });
  };

  const dateProcessing = (timestamp) => {
    const today = moment().format("DD/MM/YYYY");
    const compared = moment.unix(timestamp / 1000).format("DD/MM/YYYY");

    if (today === compared) {
      return "Today";
    } else if (moment().subtract(1, "day").format("DD/MM/YYYY") === compared) {
      return "Yesterday";
    }
    return new Date(Number(timestamp)).toLocaleString("en-GB", {
      month: "numeric",
      year: "numeric",
      day: "numeric",
    });
  };

  const addEmoji = (data) => {
    setInputText(inputText + data.emoji);
    emojiButonRef?.current?.click();
    document.getElementById("real-default-textarea").focus();
  };

  useEffect(() => {
    if (quickReplyState && quickReplyData.length && downPress) {
      document.getElementById("real-default-textarea").blur();
      setCursor((prevState) => (prevState < quickReplyData.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress, quickReplyData.length, quickReplyState]);
  useEffect(() => {
    if (quickReplyState && quickReplyData.length && upPress) {
      document.getElementById("real-default-textarea").blur();
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [quickReplyData.length, quickReplyState, upPress]);
  useEffect(() => {
    if (quickReplyState && quickReplyData.length && enterPress) {
      // const foundItem = quickReplyData.find((item) => item.command.toLowerCase().includes(searchQuickReply));
      const foundItem = quickReplyData.find((item) => item.command.includes(searchQuickReply));
      if (searchQuickReply) {
        setSelected(quickReplyData[cursor]);
        quickReplySelect(quickReplyData[cursor]);
      } else {
        setSelected(foundItem);
        quickReplySelect(foundItem);
      }
    } else if (quickReplyState && quickReplyData.length && tabPress) {
      // const foundItem = quickReplyData.find((item) => item.command.toLowerCase().includes(searchQuickReply));
      const foundItem = quickReplyData.find((item) => item.command.includes(searchQuickReply));
      if (searchQuickReply) {
        setSelected(quickReplyData[cursor]);
        quickReplySelect(quickReplyData[cursor]);
      } else {
        setSelected(foundItem);
        quickReplySelect(foundItem);
      }
    }
  }, [cursor, enterPress, quickReplyData, quickReplySelect, quickReplyState]);
  const isAtBottom = () => {
    if (messagesEndRef.current !== null) {
      const { scrollTop, scrollHeight, clientHeight } = messagesEndRef?.current;
      return Math.round(scrollTop + clientHeight) < scrollHeight;
    } else {
      return;
    }
  };

  const handleScroll = () => {
    setShowScrollButton(isAtBottom());
  };
  useEffect(() => {
    if (quickReplyState && quickReplyData.length && hovered) {
      setCursor(quickReplyData.indexOf(hovered));
    }
  }, [hovered, quickReplyData, quickReplyState]);

  useEffect(() => {
    setShowScrollButton(isAtBottom());
    messagesEndRef?.current?.addEventListener("scroll", handleScroll);
    return () => {
      messagesEndRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [showScrollButton]);

  // Button for show resolve popup
  const ResolveButton = () => {
    if (!roleFunctions.includes("chat") || Uchat.status === "PENDING" || Uchat.status === "RESOLVED") return "History";

    return "Resolve";
  };

  const { isSavedArray } = useContext(ChatContext);
  const [isSaved] = isSavedArray;

  return (
    <React.Fragment>
      {Uchat && Object.keys(Uchat).length > 0 && (
        <div className={chatBodyClass}>
          <div className="nk-chat-head" ref={bodyChatRef}>
            <div
              className="chat-head-button"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu();
              }}
            />
            <ul className="nk-chat-head-info">
              <li className="nk-chat-body-close" onClick={() => setMobileView(false)}>
                <a
                  href="#hide-chat"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-icon btn-trigger nk-chat-hide ml-n1"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </li>
              <li className="nk-chat-head-user">
                <div className="user-card">
                  <UserAvatar image={Uchat.image} icon="user-alt-fill" theme={Uchat.theme} className="overflow-hidden">
                    {/* {Uchat.active === true ? (
                      <span className="status dot dot-lg dot-success"></span>
                    ) : (
                      <span className="status dot dot-lg dot-gray"></span>
                    )} */}
                  </UserAvatar>
                  <div className="user-info">
                    <div className="lead-text">
                      {utilNumberPhoneConvention(Uchat?.name ?? Uchat?.customerPhone)}

                      {/* {Uchat.contact?.name ? Uchat.contact.name : "+" + Uchat.customerPhone} */}
                      {/* {isSaved.find((savedUser) => savedUser.id === Uchat.contactId)?.isSaved
                        ? Uchat.name
                        : "+" + Uchat.customerPhone} */}
                    </div>
                    {Uchat.tags?.map((item, idx) => {
                      return (
                        <span
                          key={idx}
                          className="badge rounded-pill text-bg-primary"
                          style={{
                            background: item.color,
                            borderColor: "#050505",
                            color: getTextColor(item.color),
                            margin: "2px",
                          }}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                    {Uchat.tickets && Uchat.tickets[0]?.campaign && (
                      <span
                        className="badge rounded-pill text-bg-primary"
                        style={{ background: "#f7f5f5", color: "#050505", margin: "2px" }}
                      >
                        {Uchat.tickets[0]?.campaign.name}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            </ul>
            <ul className="nk-chat-head-tools info-chat-head">
              <li>
                <a href={`https://wa.me/${Uchat.customerPhone}`} target="_blank" rel="noreferrer">
                  Open WA
                </a>
              </li>
              <li>
                <a
                  href="#search"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSearch();
                  }}
                  className="btn btn-icon btn-trigger text-primary chat-profile-toggle"
                >
                  <Icon name="search"></Icon>
                </a>
              </li>
              <li>
                <a
                  href="#add"
                  onClick={(e) => {
                    e.preventDefault();
                    setsidebar(false);
                  }}
                  className="btn btn-icon btn-trigger text-primary chat-profile-toggle"
                >
                  <Icon name="user-add-fill" onClick={() => setIsModalAddContactOpen(true)} />
                </a>
                {isModalAddContactOpen ? (
                  <ChatModalAddContact
                    cookies={cookies}
                    chatData={Uchat}
                    setChatData={setUchat}
                    modalDetail={isModalAddContactOpen}
                    modalToggle={() => setIsModalAddContactOpen(false)}
                  />
                ) : null}
              </li>
              {roleFunctions.includes("assign") && (
                <li className="d-none d-sm-block">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      className="dropdown-toggle btn btn-icon btn-trigger text-primary"
                      onClick={() => {
                        if (!roleFunctions.includes("assign") || Uchat.status === "RESOLVED") {
                          return;
                        } else {
                          setIsModalAssignOpen(true);
                        }
                      }}
                      // disabled={!roleFunctions.includes("assign") || Uchat.status === 'RESOLVED'}
                    >
                      <Icon name="emails"></Icon>
                    </DropdownToggle>
                  </UncontrolledDropdown>

                  <ChatModalAssign
                    chatId={id}
                    lastMessages={messages}
                    setMessages={setMessages}
                    isModalAssignOpen={isModalAssignOpen}
                    setIsModalAssignOpen={setIsModalAssignOpen}
                    cookies={cookies}
                    chat={Uchat}
                    setChat={setUchat}
                    AfterAssign={() => AfterAssign()}
                  />
                </li>
              )}

              <ChatDetailContext.Provider value={[Uchat, setUchat]}>
                <ChatModalResolve
                  cookies={cookies}
                  chat={Uchat}
                  setChat={setUchat}
                  messages={messages}
                  setMessages={setMessages}
                  isModalResolveOpen={isModalResolveOpen}
                  setIsModalResolveOpen={setIsModalResolveOpen}
                  AfterAssign={AfterAssign}
                  roleFunctions={roleFunctions}
                />
              </ChatDetailContext.Provider>
              <li className="mr-n1 mr-md-n2">
                <Button color="primary" className="btn-round" size="xs" onClick={(e) => setIsModalResolveOpen(true)}>
                  <ResolveButton />
                </Button>
              </li>
            </ul>
          </div>
          <div>
            <div
              className={!modalImage && !modalDocument && !modalVideo ? "d-none" : "send-image"}
              style={{
                width: bodyChatRef.current ? bodyChatRef.current.offsetWidth : "auto",
              }}
            >
              <div className="send-image-content">
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalImage(false);
                    setModalDocument(false);
                    setModalVideo(false);
                    setImageFile(null);
                    setDocumentFile(null);
                    setVideoFile(null);
                  }}
                  className="send-image-close text-soft"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <section style={{ height: "75%" }} className={!modalImage ? "d-none" : ""}>
                  <div className="d-flex justify-content-center p-4 h-100">
                    <img
                      alt="avatar"
                      className="rounded"
                      style={{ height: "100%" }}
                      src={imageFile != null ? URL.createObjectURL(imageFile) : ""}
                    />
                  </div>
                </section>
                <section style={{ height: "75%" }} className={!modalDocument ? "d-none" : ""}>
                  <div className="d-flex justify-content-center p-4 h-100">
                    <div className="mt-5 text-center">
                      <Icon name="file-fill" className="file-thumbnail" />
                      <div className="mt-2 file-name">{documentFile != null ? documentFile.name : ""}</div>
                      <div className="file-info">
                        {documentFile != null
                          ? convertFileSize(documentFile.size) + " - " + documentFile.type.toUpperCase()
                          : ""}
                      </div>
                    </div>
                  </div>
                </section>
                <section style={{ height: "70%" }} className={!modalVideo ? "d-none" : ""}>
                  <div className="d-flex justify-content-center p-4 h-100">
                    {/* <img
                      alt="avatar"
                      className="rounded"
                      style={{ height: "100%" }}
                      src={videoFile != null ? URL.createObjectURL(videoFile) : ""}
                      /> */}
                    <video preload="auto" controls style={{ height: "100%" }}>
                      <source src={videoFile != null ? URL.createObjectURL(videoFile) : ""} />
                    </video>
                  </div>
                </section>
                <div className="border-top d-flex align-item-center">
                  <div className="nk-chat-editor w-100">
                    <div className="nk-chat-editor-form">
                      <div className="form-control-wrap">
                        <textarea
                          style={modalDocument ? { backgroundColor: "white" } : { whiteSpace: "pre-line" }}
                          className="form-control form-control-simple no-resize"
                          rows={inputText.split("\n").length > 5 ? 5 : inputText.split("\n").length}
                          id="default-textarea"
                          onChange={modalDocument ? null : (e) => onInputChange(e)}
                          value={modalDocument ? "" : inputText}
                          ref={inputChatMedia}
                          placeholder={modalDocument ? "" : "Type your message..."}
                          onKeyDown={(e) => {
                            onKeyDownHandler(e);
                          }}
                          disabled={modalDocument ? true : false}
                        ></textarea>
                      </div>
                    </div>
                    <ul className="nk-chat-editor-tools g-2">
                      {/* <li>
                        <Button size="sm" className="btn-icon btn-trigger text-primary">
                          <Icon name="happyf-fill"></Icon>
                        </Button>
                      </li> */}
                      {/* <li>
                        <Button size="sm" className="btn-icon btn-trigger text-primary">
                          <Icon name="menu"></Icon>
                        </Button>
                      </li> */}
                      <li>
                        <Button color="primary" onClick={(e) => onTextSubmit(e)} className="btn-round btn-icon">
                          <Icon name="send-alt"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SimpleBar
            className="nk-chat-panel"
            style={{ overflowAnchor: "none" }}
            onScroll={onScrolling}
            scrollableNodeProps={{ ref: messagesEndRef, onScroll: onScrolling }}
          >
            {isLoading ? (
              <>Loading</>
            ) : (
              <>
                {afterCursor ? (
                  <div className="d-flex justify-content-center">
                    <Button outline color="light" size="sm" onClick={(e) => loadMoreMessages(e)}>
                      <span>Load More Messages</span>
                      <Icon name="reload-alt" />
                    </Button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button outline color="danger" size="sm" disabled>
                      <span>The End Of Chat</span>
                      <Icon name="na" />
                    </Button>
                  </div>
                )}
                {messages.map((item, idx) => {
                  <React.Fragment></React.Fragment>;
                  if (item !== undefined) {
                    if (dateCheck(Number(messages[idx - 1]?.timestamp)) === dateCheck(Number(item.timestamp))) {
                      if (item.type === "PURCHASE") {
                        const prevMessage = messages[idx - 1].text.split("pada");
                        const curMessage = item?.text.split("pada");
                        if (item?.text?.includes("ASSIGN") && prevMessage[0] === curMessage[0] && idx !== 0) {
                          return false;
                        } else {
                          return <SessionResolved key={item.id} item={item} />;
                        }
                      } else {
                        if (item.fromMe || item.fromBot) {
                          return (
                            <MeChat
                              cookies={cookies}
                              key={item.id}
                              item={item}
                              chat={Uchat}
                              contextMessage={contextMessage}
                              setReplyMessage={setReplyMessage}
                              setContextMessage={setContextMessage}
                              onClick={(e) => jumpReply(e)}
                              roleFunctions={roleFunctions}
                            ></MeChat>
                          );
                        } else {
                          return (
                            <YouChat
                              cookies={cookies}
                              id={item.id}
                              key={item.id}
                              item={item}
                              chat={Uchat}
                              setReplyMessage={setReplyMessage}
                              setContextMessage={setContextMessage}
                              onClick={(e) => jumpReply(e)}
                              roleFunctions={roleFunctions}
                            ></YouChat>
                          );
                        }
                      }
                    } else {
                      if (item.type === "PURCHASE") {
                        return <SessionResolved key={item.id} item={item} />;
                      } else {
                        if (item.fromMe || item.fromBot) {
                          return (
                            <React.Fragment key={item.id}>
                              <div className="time-separator">{dateProcessing(item.timestamp)}</div>
                              <MeChat
                                item={item}
                                chat={Uchat}
                                setReplyMessage={setReplyMessage}
                                setContextMessage={setContextMessage}
                                onClick={(e) => jumpReply(e)}
                                roleFunctions={roleFunctions}
                              ></MeChat>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={item.id}>
                              <div className="time-separator">{dateProcessing(item.timestamp)}</div>
                              <YouChat
                                setReplyMessage={setReplyMessage}
                                setContextMessage={setContextMessage}
                                id={item.id}
                                item={item}
                                chat={Uchat}
                                onClick={(e) => jumpReply(e)}
                                roleFunctions={roleFunctions}
                              ></YouChat>
                            </React.Fragment>
                          );
                        }
                      }
                    }
                  }
                  return <></>;
                })}
              </>
            )}
          </SimpleBar>

          {showScrollButton && (
            <div
              style={{
                width: "max-content",
                position: "absolute",
                bottom: "10px",
                right: "10px",
                backgroundColor: "#fff",
                borderRadius: "100%",
              }}
            >
              <Button size="lg" className={`btn-icon btn-trigger text-primary`} onClick={() => scrollToBottom()}>
                <Icon name="chevron-down"></Icon>
              </Button>
            </div>
          )}
          {quickReplyState ? (
            <div className="quick-reply-content">
              {quickReplyData.length ? (
                quickReplyData.map((quickReply, idx) => {
                  return (
                    <QuickReplyItem
                      active={cursor === idx}
                      mouseEnterEvent={() => setHovered(quickReply)}
                      mouseLeaveEvent={() => setHovered(undefined)}
                      key={idx}
                      command={quickReply.command}
                      content={quickReply.content}
                      clickEvent={() => {
                        quickReplySelect(quickReply);
                        setSelected(quickReply);
                      }}
                    />
                  );
                })
              ) : (
                <p>No quick reply data found!</p>
              )}
            </div>
          ) : null}
          {replyMessage ? (
            <div className="input-reply border-bottom">
              <div className="input-reply-content">
                <div className="input-reply-title">
                  {replyMessage.fromMe || replyMessage.fromBot ? <span>Anda : </span> : <span>{Uchat.name} : </span>}
                </div>
                <div className="">{replyMessage.text}</div>
              </div>
              <ul className="input-reply-close">
                <Button size="lg" onClick={(e) => setReplyMessage(null)}>
                  <Icon name="cross" />
                </Button>
              </ul>
            </div>
          ) : (
            <></>
          )}

          {/* DISABLE CHAT CONDITION */}
          {roleFunctions.includes("chat") && cookies.id === Uchat.userId && Uchat.status !== "RESOLVED" ? (
            <div className="nk-chat-editor">
              <div className="nk-chat-editor-upload  ml-n1">
                <div className={`chat-upload-option ${chatOptions ? "expanded" : ""}`}>
                  <ul className="">
                    <li>
                      <a
                        href="#img"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="img-fill" onClick={handleUploadImageClick}></Icon>
                      </a>
                      <input id="selectImage" hidden type="file" accept="image/*" onChange={handleImageFileChange} />
                    </li>
                    <li>
                      <a
                        href="#file"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="file-fill" onClick={handleUploadDocumentClick}></Icon>
                      </a>
                      <input id="selectDocument" hidden type="file" onChange={handleDocumentFileChange} />
                    </li>
                    <li>
                      <a
                        href="#video"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="video-fill" onClick={handleUploadVideoClick}></Icon>
                      </a>
                      <input id="selectVideo" hidden type="file" accept="video/*" onChange={handleVideoFileChange} />
                    </li>
                  </ul>
                </div>
                <Button
                  size="sm"
                  className={`btn-icon btn-trigger text-primary toggle-opt ${chatOptions ? "active" : ""}`}
                  onClick={() => onChatOptions()}
                >
                  <Icon name="plus-circle-fill"></Icon>
                </Button>

                <Button
                  size="sm"
                  className={classNames(showEmojiPicker ? "d-flex" : "d-none", "btn-icon btn-trigger text-primary ")}
                  onClick={() => setShowEmojiPicker(false)}
                >
                  <Icon name="cross"></Icon>
                </Button>

                <Button
                  className="btn-icon btn-trigger text-primary toggle-opt"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                >
                  <Icon name="happyf-fill"></Icon>
                </Button>

                {showEmojiPicker && (
                  <div className="emoji-picker">
                    <EmojiPicker emojiStyle="google" onEmojiClick={addEmoji} />
                  </div>
                )}
              </div>
              <div
                className={`nk-chat-editor-form ${chatOptions ? "ml-5rem" : ""}`}
                style={{ marginLeft: chatOptions ? "120px" : "" }}
              >
                <div className="form-control-wrap">
                  <textarea
                    style={{ whiteSpace: "pre-line" }}
                    className="form-control form-control-simple no-resize"
                    rows={inputText.split("\n").length > 10 ? 10 : inputText.split("\n").length}
                    id="real-default-textarea"
                    onChange={(e) => onInputChange(e)}
                    value={inputText}
                    ref={inputChat}
                    placeholder="Type your messages..."
                    onKeyDown={(e) => {
                      onKeyDownHandler(e);
                    }}
                  ></textarea>
                </div>
              </div>
              <ul className="nk-chat-editor-tools g-2">
                {/* <li>
                  <Button size="sm" className="btn-icon btn-trigger text-primary">
                    <Icon name="menu"></Icon>
                  </Button>
                </li> */}
                <li>
                  <Button color="primary" onClick={(e) => onTextSubmit(e)} className="btn-round btn-icon">
                    <Icon name="send-alt"></Icon>
                  </Button>
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          <ChatSearchSidebar searchSidebar={searchSidebar} close={closeSearchBar} messages={messages} />
          <ChatSideBar sidebar={sidebar} setsidebar={setsidebar} chat={Uchat} cookies={cookies} />
          {window.innerWidth < 1550 && sidebar && (
            <div onClick={() => toggleMenu()} className="nk-chat-profile-overlay" />
          )}

          <ChatModalTagAssign
            cookies={cookies}
            isModalTagAssignOpen={isModalTagAssignOpen}
            setIsModalTagAssignOpen={setIsModalTagAssignOpen}
            chatId={chatId}
            setChatId={setChatId}
            checkedTag={Uchat.tags}
            setCheckedTag={setCheckedTag}
            chat={Uchat}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ChatBody;
