import moment from "moment";
import Papa from "papaparse";
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import {
  serviceDeleteCampaigns,
  serviceGetAllCampaigns,
  serviceGetCampaigns,
  serviceGetCampaignsByField,
  serviceGetCampaignsByQuery,
} from "services/campaign";
import { getDepartments } from "services/departments";
import { getTagLeadSource } from "services/tags";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Row,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import getTextColor from "../../components/custom/getTextColor";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { CampaignContext } from "./CampaignContext";
import campaignData from "./campaignData";
import { serviceGetProfile } from "services/settings";

const StyledDataTableRow = styled(DataTableRow)`
  padding-bottom: 6px !important;
  padding-top: 12px !important;
`;

const Campaign = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  const [dataProfile, setDataProfile] = useState([]);
  const [businessOption, setbusinessOption] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(cookies.departmentIds);
  const { contextData } = useContext(CampaignContext);
  const [data, setData] = contextData;
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    link: "",
    departemenTarget: "",
    waTarget: "",
    waNumber: "",
    active: true,
  });
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 1,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [orderBy, setOrderBy] = useState("DESC");
  const [isSorting, setIsSorting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["campaigns"].access || [];
  const [selectedLeadSource, setSelectedLeadSource] = useState("all");

  // const getProfile = async () => {
  //   const responseProfile = await serviceGetProfile(cookies.SIDikey);
  //   const resultData = responseProfile.data.departments;
  //   const finalData = [];
  //   for (let departments of resultData){
  //     const tempData = {
  //       id: departments.id,
  //       color: departments.color,
  //       name: departments.name,
  //     }
  //     finalData.push(tempData)
  //   }
  //   setDataProfile(finalData);
  // };

  const refreshCampaigns = async (pageNumber) => {
    setLoading(true);
    try {
      const responseGetCampaigns = await serviceGetCampaigns(
        cookies.SIDikey,
        roleFunctions,
        cookies.departmentIds,
        cookies.id,
        pageNumber,
        selectedLeadSource
      );
      setMetaData(responseGetCampaigns.data.meta);
      if (new RegExp(/20+/).test(responseGetCampaigns.status)) {
        const campaignData = responseGetCampaigns.data.data;
        setData(campaignData);
      } else {
        console.log("error refreshCampaigns", responseGetCampaigns);
      }
    } catch (error) {
      console.error("Error refreshing campaigns:", error);
    } finally {
      setLoading(false);
    }
  };

  const refreshAllQuickReply = async () => {
    const resp = await serviceGetAllCampaigns(cookies.SIDikey);
    const tempData = resp.map((item) => {
      return {
        ...item,
        createdBy: item.createdBy?.firstName,
        updatedBy: item.updatedBy?.firstName,
        departments: item.departments?.map((i) => i.name).toString(),
      };
    });
    setAllData(tempData);
  };

  const refreshCampaignsByQuery = async (pageNumber, query, field, departmentId) => {
    const responseGetCampaigns = await serviceGetCampaignsByQuery(
      cookies.SIDikey,
      roleFunctions,
      departmentId,
      cookies.id,
      pageNumber,
      query.replace(/,+/g, ","),
      field,
      selectedLeadSource
    );
    console.log('tes', responseGetCampaigns)

    setMetaData(responseGetCampaigns.data.meta);
    if (new RegExp(/20+/).test(responseGetCampaigns.status)) {
      const campaignData = responseGetCampaigns.data.data;
      setData(campaignData);
    } else {
      console.log("error refreshCampaigns", responseGetCampaigns);
    }
  };

  const refreshDepartmentData = async () => {
    try {
        const responseGetDepartments = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
        setDepartmentData(responseGetDepartments.data.data);
    } catch (error) {
        console.error("Failed to fetch department data:", error);
    }
};

  const refreshCampaignsByField = async (pageNumber, order, field, queryText, fieldText) => {
    const responseGetCampaigns = await serviceGetCampaignsByField(
      cookies.SIDikey,
      roleFunctions,
      cookies.departmentIds,
      cookies.id,
      pageNumber,
      order,
      field,
      queryText.replace(/,+/g, ","),
      fieldText,
      selectedLeadSource
    );

    setMetaData(responseGetCampaigns.data.meta);

    if (new RegExp(/20+/).test(responseGetCampaigns.status)) {
      const campaignData = responseGetCampaigns.data.data;
      setData(campaignData);
    } else {
      console.log("error refreshCampaigns", responseGetCampaigns);
    }
  };
  const [activeSearch, setActiveSearch] = useState("");
  const [sortKeyword, setSortKeyword] = useState("");

  const [queryName, setQueryName] = useState("");
  const [queryLink, setQueryLink] = useState("");
  const [queryProduct, setQueryProduct] = useState("");
  const [queryBusiness, setQueryBusiness] = useState("");
  const [queryUserName, setQueryUserName] = useState("");
  const [queryLeadSource, setQueryLeadSource] = useState("");

  const [fieldName, setFieldName] = useState("");
  const [fieldLink, setFieldLink] = useState("");
  const [fieldProduct, setFieldProduct] = useState("");
  const [fieldUserName, setFieldUserName] = useState("");

  const [fieldSource, setFieldSource] = useState("");

  const [queryText, setQueryText] = useState("");
  const [fieldText, setFieldText] = useState("");

  useEffect(() => {
    setQueryText(queryName + queryLink + queryProduct + queryUserName, queryLeadSource);
    setFieldText(fieldName + fieldLink + fieldProduct + fieldUserName + fieldSource);
  }, [
    fieldLink,
    fieldName,
    fieldProduct,
    fieldUserName,
    fieldSource,
    queryLink,
    queryName,
    queryProduct,
    queryUserName,
    queryLeadSource,
    selectedLeadSource,
  ]);

  useEffect(() => {
    refreshCampaigns(1);
  }, [selectedLeadSource]);
  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  useMemo(() => {
    refreshCampaigns(1);
    refreshDepartmentData();
    refreshAllQuickReply();
  }, []);

  useEffect(() => {
    refreshCampaigns(1);
    let newData;
    newData = campaignData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
      });
      setNonFixedHeight(tempHeight);
    }
  }, [data]);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = campaignData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...campaignData]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const convertObjectToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };
  const handleDownload = (objectData) => {
    const csv = convertObjectToCSV(objectData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `campagin_summary.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure for delete " + "" + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteCampaigns(id, cookies.SIDikey);
          if (responseDelete.status === 202) {
            Swal.fire("Berhasil Delete Campaign", "Delete Campaign", "success");
            refreshCampaigns(1);
          } else if (responseDelete.status === 500) {
            Swal.fire("Gagal Delete Campaign", "Data yang ingin anda delete sedang digunakan", "error");
          } else {
            Swal.fire("Gagal Delete Campaign", responseDelete.message, "error");
          }
        } catch (error) {
          console.log("handle delete error", error);
        }
      }
    });
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const oneDepartment = (selectedDepartment) => {
    if (typeof selectedDepartment === 'string') {
      return [selectedDepartment];
    }
    return selectedDepartment;
  }

  const isSelectedOneDepartment = oneDepartment(selectedDepartment)
  // Change Page
  const paginate = (pageNumber) =>
    isSorting
  ? refreshCampaignsByField(pageNumber, orderBy, sortKeyword, queryText, fieldText)
  : queryText !== "" || isSelectedOneDepartment.length < 2
  ? refreshCampaignsByQuery(pageNumber, queryText, fieldText, selectedDepartment)
  : refreshCampaigns(pageNumber);
  
  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };
  useEffect(() => {
    if (departmentData) {
      let currentDepartement = departmentData;

      if (roleFunctions.includes("Access_Own_Dept")) {
        currentDepartement = departmentData.filter((dpt) => cookies.departmentIds.includes(dpt.id));
      }

      const newBusinessOption = [
        { value: "ALL", label: "ALL" },
        ...currentDepartement.map((item) => ({ label: item.name, value: item.id })),
      ];

      setbusinessOption(newBusinessOption);
    }
  }, [departmentData]);

  useEffect(() => {
    // Log businessOption after it's updated
    console.log("Updated businessOption", businessOption);
  }, [businessOption]);

  useEffect(() => {
    if (queryText !== "" && fieldText !== 0) {
      setonSearch(true);
      refreshCampaignsByQuery(1, queryText, fieldText, selectedDepartment);
    } else {
      setonSearch(false);
      refreshCampaigns(1);
    }
  }, [fieldText, queryText]);

  const onSortClicked = (id, order) => {
    setOrderBy(order);
    refreshCampaignsByField(1, order, id, queryText, fieldText);
    setIsSorting(true);
    setSortKeyword(id);
  };
  const onSelectChangeBusiness = (e) => {
    if (e.value !== "ALL") {
      setQueryBusiness(e.value);
      refreshCampaignsByQuery(1, queryText, fieldText, e.value);
      setSelectedDepartment(e.value);
      console.log("tes2");
    } else {
      setQueryBusiness("");
      refreshCampaigns(1);
      setSelectedDepartment(cookies.departmentIds);
      console.log("tes");
    }
  };

  const [leadSourceOption, setLeadSourceOption] = useState([]);

  const fetchLeadSource = async () => {
    const responseGetLeadSources = await getTagLeadSource(cookies.SIDikey, ["Access_All_Dept"], 0);
    setLeadSourceOption([
      {
        value: "all",
        label: "All",
      },
      ...responseGetLeadSources.data.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    ]);
  };

  useEffect(() => {
    fetchLeadSource();
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Campaign
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>
                Campaign is a Whatsapp link from a campaign activities. You can use one or more link for different
                campaign. Add the new campaign by clicking "Tambah Baru" button.
              </p>
            </CardBody>
          </Card>

          <Block className="d-flex justify-content-end">
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Export</span>
                        </a>
                      </li> */}
                    <li>
                      <Button
                        disabled={allData.length === 0}
                        href="#export"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(allData);
                        }}
                        className="btn btn-white btn-outline-light"
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Download</span>
                      </Button>
                    </li>
                    {!roleFunctions.includes("create") || (
                      <li className="nk-block-tools-opt">
                        <Link to={`${process.env.PUBLIC_URL + "campaign/add"}`}>
                          <Button color="primary" className="btn">
                            <i className="ni ni-plus"></i>
                            <span>Tambah Baru</span>
                          </Button>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <Block>
            <DataTable>
              <Row>
                <Col sm="5" lg="5" md="5" xxl="5" className="fixed-column border-right">
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow>
                        <div className="sub-text d-flex justify-content-between align-items-center">No</div>
                      </DataTableRow>
                      <DataTableRow>
                        <div style={{ width: "150px", display: "flex" }}>
                          <span className="sub-text">Link Campaign</span>
                          <Button size="xs" id="linkCampaign">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="linkCampaign">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("link.name", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("link.name", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow>
                        <div style={{ width: "100px", display: "flex" }}>
                          <span className="sub-text">Name</span>
                          <Button size="xs" id="campaignName">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="campaignName">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.name", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.name", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow>
                        <div style={{ width: "150px", display: "flex" }}>
                          <span className="sub-text">Lead Source</span>
                          <Button size="xs" id="leadSource">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="leadSource">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("tag.name", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("tag.name", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>

                      <DataTableRow>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Product</span>
                          <Button size="xs" id="campaignProduct">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="campaignProduct">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.product", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.product", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    <DataTableHead>
                      <DataTableRow></DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            id="link-search"
                            type="text"
                            className="form-control w-100"
                            onChange={(e) => {
                              setQueryLink("," + e.target.value);
                              setFieldLink(",link.name");
                              if (e.target.value === "") {
                                let fieldN = fieldLink;
                                setFieldLink(fieldN.replace(",link.name", ""));
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                          />
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            id="campaign-search"
                            type="text"
                            className="form-control w-100"
                            onChange={(e) => {
                              setQueryName("," + e.target.value);
                              setFieldName(",campaign.name");
                              if (e.target.value === "") {
                                let fieldN = fieldName;
                                setFieldName(fieldN.replace(",campaign.name", ""));
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                          />
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <div className="form-control-wrap mt-1">
                            <RSelect
                              onChange={(e) => {
                                setSelectedLeadSource(e.value);
                              }}
                              options={leadSourceOption}
                            />
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            id="product-search"
                            type="text"
                            className="form-control w-100"
                            onChange={(e) => {
                              setQueryProduct("," + e.target.value);
                              setFieldProduct(",campaign.product");
                              if (e.target.value === "") {
                                let fieldN = fieldProduct;
                                setFieldProduct(fieldN.replace(",campaign.product", ""));
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                          />
                        </div>
                      </DataTableRow>
                    </DataTableHead>
                    {currentItems.length > 0
                      ? currentItems.map((item, index) => {
                          return (
                            <DataTableItem key={item.id}>
                              <DataTableRow
                                noWrap
                                customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                              >
                                <span>{(metaData?.page - 1) * metaData?.take + (index + 1)}</span>
                              </DataTableRow>
                              <DataTableRow>
                                <a href={`https://astronacci.org/${item.link.name}`} target="_blank" rel="noreferrer">
                                  {item.link.name}
                                </a>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{item.name}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                {item.leadSourceData && <span>{item.leadSourceData.name}</span>}
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{item.product}</span>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      : null}
                  </DataTableBody>
                </Col>
                <Col sm="7" lg="7" md="7" xxl="7" className="nfixed-column">
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow noWrap>
                        <span className="sub-text">Department Target</span>
                      </DataTableRow>
                      <DataTableRow>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Target</span>
                          <Button size="xs" id="waTarget">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="waTarget">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.waTarget", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.waTarget", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Jumlah Klik</span>
                          <Button size="xs" id="clickCount">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="clickCount">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.clickCount", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.clickCount", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Jumlah Chat</span>
                          <Button size="xs" id="chatCount">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="chatCount">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.chatCount", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.chatCount", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Jumlah Resolve</span>
                          <Button size="xs" id="resolveCount">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="resolveCount">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.resolveCount", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.resolveCount", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div style={{ display: "flex" }}>
                          <span className="sub-text">Aktif</span>
                          <Button size="xs" id="status">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="status">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.status", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("campaign.status", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <span className="sub-text pl-4">Aksi</span>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <span className="sub-text">Modify At</span>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <span className="sub-text">Modify By</span>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <span className="sub-text">Created At</span>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <span className="sub-text">Created By</span>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    <DataTableHead>
                      <StyledDataTableRow>
                        <div className="form-control-wrap mt-1">
                          <RSelect onChange={onSelectChangeBusiness} options={businessOption} />
                        </div>
                      </StyledDataTableRow>
                    </DataTableHead>
                    {currentItems.length > 0
                      ? currentItems.map((item, index) => {
                          const createdBy = item.createdBy.firstName + " " + item.createdBy.lastName;
                          const updatedBy =
                            item.updatedBy !== null || undefined
                              ? item.updatedBy.firstName + " " + item.updatedBy.lastName
                              : createdBy;

                          return (
                            <DataTableItem key={item.id}>
                              <DataTableRow ref={addToRefs}>
                                <span
                                  className="badge rounded-pill text-bg-primary"
                                  style={{
                                    background: item.department?.color ? item.department.color : "#fffff",
                                    borderColor: item.department?.color ? item.department.color : "#fffff",
                                    color: getTextColor(item.department?.color ? item.department.color : "#fffff"),
                                  }}
                                >
                                  {item.department?.name}
                                </span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{item.waTarget ? item.waTarget : "single"}</span>
                              </DataTableRow>
                              {/* 
                              <DataTableRow noWrap>
                                <span>{item?.username}</span>
                              </DataTableRow>
                              */}
                              <DataTableRow noWrap>
                                <span>{item?.clickCount ? item?.clickCount : 0}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{item?.chatCount ? item?.chatCount : 0}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{item?.resolveCount ? item?.resolveCount : 0}</span>
                              </DataTableRow>
                              <DataTableRow>
                                <ul className="list-status">
                                  <li>
                                    <Icon
                                      className={`table-icon text-${item?.status === "ACTIVE" ? "success" : "warning"}`}
                                      name={item?.status === "ACTIVE" ? "check-circle" : "cross-circle"}
                                    ></Icon>
                                  </li>
                                </ul>
                              </DataTableRow>
                              <DataTableRow>
                                <ul className="nk-tb-actions table-action">
                                  <li className="nk-tb-action">
                                    <Link
                                      id={"detail" + item.id}
                                      to={{
                                        pathname: process.env.PUBLIC_URL + "/campaign/detail",
                                        state: {
                                          campaign: item,
                                          edit: false,
                                        },
                                      }}
                                      className="btn btn-trigger btn-icon"
                                    >
                                      <Icon name="eye-fill" />
                                    </Link>
                                    <UncontrolledTooltip autohide={false} placement="top" target={"detail" + item.id}>
                                      Detail
                                    </UncontrolledTooltip>
                                  </li>
                                  {!roleFunctions.includes("update") || (
                                    <li className="nk-tb-action">
                                      <Link
                                        id={"edit" + item.id}
                                        to={{
                                          pathname: process.env.PUBLIC_URL + "/campaign/detail",
                                          state: {
                                            campaign: item,
                                            edit: true,
                                          },
                                        }}
                                        className="btn btn-trigger btn-icon"
                                      >
                                        <Icon name="edit-alt-fill" />
                                      </Link>
                                      <UncontrolledTooltip autohide={false} placement="top" target={"edit" + item.id}>
                                        Edit
                                      </UncontrolledTooltip>
                                    </li>
                                  )}
                                  {!roleFunctions.includes("delete") || (
                                    <li className="nk-tb-action" onClick={() => handleDelete(item.id)}>
                                      <TooltipComponent
                                        tag="a"
                                        containerClassName="btn btn-trigger btn-icon"
                                        id={"delete" + item.id}
                                        icon="trash-fill"
                                        direction="top"
                                        text="Hapus"
                                      />
                                    </li>
                                  )}
                                </ul>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{updatedBy}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{moment(item.createdAt).format("MMM DD, YYYY, h:mm")}</span>
                              </DataTableRow>
                              <DataTableRow noWrap>
                                <span>{createdBy}</span>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      : null}
                  </DataTableBody>
                </Col>
              </Row>
              <div className="card-inner">
                {currentItems.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={metaData?.take}
                    totalItems={metaData?.itemCount}
                    paginate={paginate}
                    currentPage={metaData?.page}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Campaign;
