import { axiosPrivate } from "api/axiosCore";

const limit = 15;
const basic_url = 'api/permissions/'
const ALL_DEPT = "Access_All_Dept";
const OWN_DEPT = "Access_Own_Dept";
const OWN = "Access_Own";

export const serviceGetSodAllPermission = async (token, query, roleFunctions, searchParam,  page, limit = 15) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
    let basicUrl = `api/permits/?take=${limit}&page=${page || 1}`
    roleFunctions = roleFunctions || [];
    var response = null;

    Object.keys(searchParam).forEach(key => {
      if(searchParam[key]){
        basicUrl = `${basicUrl}&${key}=${searchParam[key]}`
      }
    });

    response = await axiosPrivate.get(basicUrl);

    console.log(basicUrl, response);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceGetSodPermission = async (token, query, roleFunctions,  page) => {
    try {
      axiosPrivate.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  
      roleFunctions = roleFunctions || [];
      var response = null;
      response = await axiosPrivate.get(basic_url);
      console.log(response);
      return response;
    } catch (error) {
      if (!error.response) {
        return { status: "No Server Response" };
      } else {
        return error.response;
      }
    }
  };

export const servicePostSodPermission = async (token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.post(basic_url, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const servicePutSodPermission = async (id, token, data) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.put(basic_url + id, data);

    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};

export const serviceDeleteSodPermission = async (id, token) => {
  try {
    axiosPrivate.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });

    const response = await axiosPrivate.delete(basic_url + id);
    return response;
  } catch (error) {
    if (!error.response) {
      return { status: "No Server Response" };
    } else {
      return error.response;
    }
  }
};
